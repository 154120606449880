<template>
  <div class="list">
    <div class="topcontent">
      <el-select
        clearable
        @change="handleShopChange"
        v-model="ruleForm.shopid"
        class="m-2"
        placeholder="所属门店"
        size="large"
      >
        <el-option
          v-for="item in shopList"
          :key="item.shopId"
          :label="item.shopName"
          :value="item.shopId"
        ></el-option>
      </el-select>

      <el-date-picker
        @change="handleDateChnage"
        v-model="ruleForm.date"
        type="month"
        placeholder="选择年月"
      />
    </div>

    <page-content
      :contentTableConfig="contentTableConfig3"
      :isButtonStyle="true"
      :dataCount="memberInfoCount"
      :dataList="dataList"
    >
      <template #serveqty="scope">
        {{ (scope.row.serveqty / 100).toFixed(2) }}
      </template>

      <template #saleqty="scope">
        {{ (scope.row.saleqty / 100).toFixed(2) }}
      </template>

      <template #tichengQty="scope">
        {{ (scope.row.tichengQty / 100).toFixed(2) }}
      </template>

      <template #serveyeji="scope">
        {{ (scope.row.serveyeji / 100).toFixed(2) }}
      </template>

      <template #saleyeji="scope">
        {{ (scope.row.saleyeji / 100).toFixed(2) }}
      </template>
    </page-content>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import { contentTableConfig3 } from '../../config'
import PageContent from '@/components/page-content2/src/page-content.vue'
import { testTicheng4, getShopList } from '@/service/main/base'
import { COMPANY_ID } from '@/common/constant'
import dayjs from 'dayjs'
import cache from '@/utils/cache'

export default defineComponent({
  props: {

  },
  components: {
    PageContent
  },
  setup() {

    const dataList = ref([])
    const handleCount = item => {
      // console.log(item);
    }

    const shopList = ref([])
    const ruleForm = reactive({
      shopid: "",
      date: "",
      y: '',
      m: ""
    })

    const handleShopChange = () => {
      getTcFunc()
    }

    const handleDateChnage = (value) => {
      ruleForm.y = dayjs(value).format('YYYY')
      ruleForm.m = dayjs(value).format('MM')
      getTcFunc()
    }

    const getTcFunc = async () => {
      try {
        let { shopid, y, m } = ruleForm
        const res = await testTicheng4(shopid, y, m)
        let list = res.data.levelist
        let arr = []
        list.forEach(item => {
          item.emps.forEach(ele => {
            let ticheng = ele.ticheng
            let { serveqty,
              saleqty,
              lastupdate,
              saleyeji,
              serveyeji
            } = ticheng

            ele['serveqty'] = serveqty
            ele['saleqty'] = saleqty
            ele['lastupdate'] = lastupdate
            ele['saleyeji'] = saleyeji
            ele['serveyeji'] = serveyeji
          })
          arr.push(...item.emps)
        })
        dataList.value = arr
      } catch (error) {

      }
    }

    const initPage = async () => {
      let adminInfo = cache.getCache('adminInfo')
      ruleForm.shopid = adminInfo.shopId
      ruleForm.y = dayjs().format('YYYY')
      ruleForm.m = dayjs().format('MM')
      ruleForm.date = dayjs()
      getTcFunc()
      let sres = await getShopList(COMPANY_ID)
      shopList.value = sres.data.list

    }
    initPage()

    return {
      ruleForm,
      shopList,
      dataList,
      contentTableConfig3,
      handleCount,
      handleShopChange,
      handleDateChnage
    }

  }
})
</script>

<style scoped lang="less">
.list {
  .topcontent {
    margin-bottom: 20px;

    &:deep(.el-select) {
      margin-right: 10px;
    }
  }
}
</style>
